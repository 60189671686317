import React from "react";
import './MTB.css';
import logo from '../images/archimedessocietylogo.png';
import kyle from '../images/kseng.png';
import ishi from '../images/ibansal.png';
import cole from '../images/cwalton.png';
import placeholder from '../images/placeholder.png';

function MTB () {
    return (
        <>
            
            <box className="meet-the-board">
                <header className="mtb-title">Meet The Board</header>
                
                <div className="mtb-container">
                    
                    <img src= {logo} className="mtb-img"/>
                    <ul className="mtb-positions">
                        <li className="mtb-item"><img src= {placeholder} className="julie"></img><p className="position">President</p><br/><p className="name">Julie Trong</p></li>
                        <li className="mtb-item"><img src= {cole} className="cole"></img><p className="position">Vice President</p><br/><p className="name">Cole Walton</p></li>
                        <li className="mtb-item"><img src= {ishi} className="ishi"></img><p className="position">Treasurer</p><br/><p className="name">Ishi Bansal</p></li>
                        <li className="mtb-item"><img src= {kyle} className="kyle"></img><p className="position">Events Coordinator</p><br/><p className="name">Kyle Seng</p></li>
                    </ul>
               </div>
            </box>
        </>
    )
}


export default MTB