import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Form } from 'react-router-dom';
import Home from './components/pages/Home';
import AboutUs from './components/pages/AboutUs';
import Astra from './components/pages/Astra';
import Juvo from './components/pages/Juvo';
import Infinitum from './components/pages/Infinitum';
import Gallery from './components/pages/Gallery';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about-us' element={<AboutUs/>}/>
          <Route path='/astra' element={<Astra/>}/>
          <Route path='/infinitum' element={<Infinitum/>}/>
          <Route path='/juvo' element={<Juvo/>}/>
          <Route path='/gallery' element={<Gallery/>}/>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}


export default App;
