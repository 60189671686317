import React from "react";
import "./Footer.css";
import { Link } from 'react-router-dom';
import footerBanner from '../images/archimedessocietybanner.png';

function Footer () {
    return (
        <>
            <header className="horizontal-line1"></header>
            <box className="footer">
                <div className="footer-container">
                    <p className="footer-body">Thank you for visiting our website. If you have any questions or would like to learn more about the Archimedes Society, please don't hesitate to get in touch with us.</p>
                    <Link to="mailto:bornahokie@vt.edu" className="footer-link">Contact Us!</Link>   
                </div>
                
            </box>
            <img src= {footerBanner} className="banner"></img>
        </>
    )
}

export default Footer