import React from 'react';
import '../../App.css';
import Mission from '../Mission';
import Teams from '../Teams';
import MTB from '../MTB'; 



function Home() {
      return (
        <>
            <Mission />
            <Teams />
            <MTB />
        </>
      )  
}

export default Home