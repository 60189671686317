import React from 'react';
import './Teams.css';
import astraLogo from '../images/newnasa.png'
import infinLogo from '../images/microsoftremake.png'
import juvoLogo from '../images/newresna (1).png'

function Teams() {
    return (
        <>
            <box className="teams">
                <div className="teams-container">
                    <ul className="teams-menu">
                        <li className="teams-item">
                            <img src= {astraLogo} className="astra-logo"></img><box className="astra-title">Astra</box>
                        </li>
                        <li className="teams-item">
                            <box className="ilogo-container">
                                <img src= {infinLogo} className="infinitum-logo"></img>
                            </box> 
                            <box className="infinitum-title">Infinitum</box>
                        </li>
                        <li className="teams-item">
                            <img src= {juvoLogo} className="juvo-logo"></img><box className="juvo-title">Juvo</box>
                        </li>
                    </ul>
                </div>
            </box>
            
            
        </>
    )
}

export default Teams