import React from 'react'
import './AboutTitle.css'

function AboutTitle() {
  return (
    <div>
        <header className="horizontal-line"></header>
      <box className="about-us-title">
        <div className="title-container">
            <p className="atitle">About Us</p>
        </div>
      </box>

    </div>
  )
}

export default AboutTitle
