import React from 'react';
import '../../App.css';
import AstraBody from '../AstraBody';
import AstraTitle from '../AstraTitle';




function Astra() {
      return (
        <>
            <AstraTitle/>
            <AstraBody/>
        </>
      )  
}

export default Astra