import React from 'react';
import '../../App.css';




function Gallery() {
      return (
        <>

        </>
      )  
}

export default Gallery