import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import menuIcon from '../images/hamburger.jpg';
import closeButton from '../images/closeButton.png';
import logo from '../images/archimedessocietylogo.png';
import  { Button } from './Button';
import'./Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if(window.innerWidth <= 960) {
      setButton(false);
    }
    else {
      setButton(true);
    }
  };

  window.addEventListener('resize', showButton);
  return (
    <>
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" className="navbar-logo">
                <img className="logo-pic" src= {logo}/> Archimedes Society
                </Link>
                
                <div className='menu-icon' onClick={handleClick}>
                  <img src={click ? closeButton : menuIcon} alt=""/>
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                  <li className='nav-item'>
                    <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                      Home
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/about-us' className='nav-links' onClick={closeMobileMenu}>
                      About Us
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/astra' className='nav-links' onClick={closeMobileMenu}>
                      Astra
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/infinitum' className='nav-links' onClick={closeMobileMenu}>
                      Infinitum
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/infinitum' className='nav-links' onClick={closeMobileMenu}>
                      Juvo
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/sign-up' className='nav-links-mobile' onClick={closeMobileMenu}>
                      Interested in Joining?
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/gallery' className='nav-links' onClick={closeMobileMenu}>
                      Gallery
                    </Link>
                  </li>
                </ul>
                {button && <Button buttonStyle='btn--outline'>INTERESTED IN JOINING?</Button>}
            </div>
        </nav>
    </>
  )
}

export default Navbar
