import React from 'react'
import './AstraTitle.css'

function AstraTitle() {
  return (
    <div>
        <header className="horizontal-line"></header>
      <box className="about-us-title">
        <div className="title-container">
            <p className="atitle">Astra</p>
        </div>
      </box>

    </div>
  )
}

export default AstraTitle
