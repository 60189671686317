import React from 'react';
import './Mission.css';

function Mission() {
    return (
        <>
            <header className="horizontal-line"></header>
            <box className="mission-statement">
                <div className="mission-statement-container">
                    <header className="mission-title">Mission Statement</header>
                    <div className="vertical-line"></div>
                    <p className="mission-body">"We foster an environment where students support diverse teams that can work hands on to develop solutions for the complex engineering challenges of the future."</p>
                </div>
            </box>
        </>
    )
}

export default Mission