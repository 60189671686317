import React from 'react';
import '../../App.css';
import AboutTitle from '../AboutTitle';
import Body from '../Body';



function AboutUs() {
      return (
        <>
            <AboutTitle/>
            <Body/>
        </>
      )  
}

export default AboutUs