import React from 'react';
import blueLogo from '../images/archimedessocietylogo.png';
import './Body.css'

function Body() {
  return (
    <div>
      <box className="background-box">
        <box className="history">
            <header className="history-title">Our History</header>
            <div className="vertical-line1"></div>
            <p className="history-body"><em>"</em>The Archimedes Society started in 2015 as a student-led group of 3 first-year students competing in the NASA Mirco-g NExT Challenge. Since then, we have expanded and had greater participation from first-year students in national competitions each year. We are now a society of over 40 engineers building better futures together.<em>"</em></p>
        </box>
        <box>
            <img src={blueLogo} className="blogo"/>
        </box>
        <box className="white-logo">
          <img src={blueLogo} className="wlogo"/>
      </box>
      <box className="purpose">
          <p className="purpose-body"><em>"</em>We are a professional networking organization that manages, develops, and funds first-year design teams. We give freshman the oportunity to create a product and submit it to design competitions. Taking on a technical role allows freshman to gain valuable technical and interpersonal skills that can be transferred to real-world experience.<em>"</em></p> 
          <div className="vertical-line2"></div>
          <header className="purpose-title">Our Purpose</header>
      </box>
      <box className="values">
          <header className="values-title">Our <br/>Values</header>
          <div className="vertical-line3"></div>
          <p className="values-body"><em>"</em>Archimedes operates under two guiding principles: <br/>Capability is not defined by one's experience.<br/>Learning is best accomplished through interest, curiosity, and hard work.<em>"</em></p>
      </box>
      <box className="blue-logo">
        <img src={blueLogo} className="blogo2"/>
      </box>
      <box className="white-logo">
        <img src={blueLogo} className="wlogo2"/>
      </box>
      <box className="structure">
          <p className="structure-body"><em>"</em>Our organization is comprised of three main groups: Coordinators, Advisors, and Teams. The Coordinators are members of the board, who coordinate with faculty and funding boards. The Advisors are typically former Team members who advise the freshman design teams based off their experience. Lastly, the Teams are all first-year students competing in engineering design challenges.<em>"</em></p> 
          <div className="vertical-line4"></div>
          <header className="structure-title">Our Structure</header>
      </box>
    </box>
      
    </div>
  )
}

export default Body
