import React from 'react';
import './AstraBody.css'

function AstraBody() {
    return (
      <div>
        <box className="background-box-astra">
            <box className="att-title">About the Team</box>
            <box className="att-description">Astra is an all-freshman design team that is mentored by upperclassmen to compete in the NASA Micro-G NExT Challenge. Astra provides real world experience for freshman students as students will be tasked with proposing solutions to problems that NASA has and these solutions can possibly be applied to NASA's Artemis missions. Freshmen will also gain experience from competing against upper-level senior design teams from across the United States. If NASA accepts Astra's proposal, students will have the opportunity to go to Houston, Texas to the Neutral Buoyancy Lab where the team will test their prototype. Lastly, Astra provides freshman will valuable manufacturing process knowledge as well as technical writing experience needed to propose and build a prototype for NASA.</box>
            <box className="atc-title">About the Competition</box>
            <box className="atc-description"></box>
            <box className="past-entries-title">Past Entries</box>
            <box className="how-to-join-title">How to Join</box>
            <box className="past-entries-description"></box>
            <box className="how-to-join-description"></box>
        </box>
      </div>
    )
  }
  
  export default AstraBody